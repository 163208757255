import type { Coin, CurrencyDeep } from '@base/types'

/**
 * Resolves the currency by fetching the price from the specified price feed.
 *
 * @param {CurrencyDeep} c - The currency object to resolve.
 * @param {Coin} [czkCoin] - The CZK coin object for showingPrice conversion purposes.
 * @return {Promise<Coin>} - The resolved currency object with the price and other properties.
 */
export const resolveCurrencyToCoin = async (c: CurrencyDeep, czkCoin?: Coin): Promise<Coin> => {
  const coin: Coin = {
    ...c,
    name: c.label,
    showingPrice: 0,
    price: 0,
    chart: c.chartImg ?? undefined,
  }

  try {
    // resolve price
    if (c.priceFeed === 'KRAKEN') {
      const price = await $fetch(`/api/prices/kraken/${c.pairEur}`)
      coin.price = price.price
      coin.change24HPercent = price.dailyChange
    }
    else if (c.priceFeed === 'CURRENCYLAYER') {
      const price = await $fetch(`/api/prices/currencylayer/${c.pairEur}`)
      coin.price = price ?? 0
    }
    else if (c.priceFeed === 'CUSTOM')
      coin.price = c.priceEur ?? 0
    else {
      console.error('Price feed not implemented', c.priceFeed)
      // todo: log to sentry
    }
  }
  catch (error) {
    console.error('Error fetching price:', error)
    // todo: log to sentry (it should be logged automatically by the catch statetment -> check if it works)
  }

  // resolve showingPrice
  if (c.showingPrice) {
    coin.showingPrice = c.showingPrice
  }
  else if (czkCoin) {
    const czkDefaultCharge = czkCoin!.charges?.find(c => c.isDefault)
    const charge = roundMath((100 - (czkDefaultCharge?.profitPlatformPercent ?? 0) - (czkDefaultCharge?.platform.profitBitbeliPercent ?? 0)) / 100, 2)
    coin.showingPrice = coin.price / czkCoin!.price / charge
  }

  return coin
}
